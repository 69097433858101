<template>
  <div>
    <div style="display: flex">
      <el-input style="width: 300px;margin-right: 24px" v-model="search" :placeholder="fun.getLanguageText($store.state.language, '请输入关键词进行搜索')"></el-input>
      <el-button type="primary">{{fun.getLanguageText($store.state.language, '搜索')}}</el-button>
    </div>
    <div>

      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column :label="fun.getLanguageText($store.state.language, '序号')" prop="id" width="120">
        </el-table-column>
        <el-table-column :label="fun.getLanguageText($store.state.language, '模板名称')" prop="templateName">
        </el-table-column>
        <el-table-column :label="fun.getLanguageText($store.state.language, '创建用户')" prop="user">
        </el-table-column>
        <el-table-column fixed="right" :label="fun.getLanguageText($store.state.language, '设置')" width="300">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="edit(scope.row.id)">
              {{fun.getLanguageText($store.state.language, '编辑')}}
            </el-button>
            <el-button size="small" type="text" @click="delTemplate(scope.row.id)">
              {{fun.getLanguageText($store.state.language, '删除')}}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :current-page="pageData.currentPage" :page-size="pageData.pageSize"
                     :page-sizes="pageData.pageSizes"
                     :total="pageData.total" background class="footer"
                     layout="sizes, prev, pager, next, jumper, total" @size-change="sizeChange"
                     @current-change="currentChange">
      </el-pagination>

    </div>
  </div>
</template>

<script>
export default {
  name: "formulaTemplate",
  data() {
    return {
      search: "",
      pageData: {
        total: 0, // 总条数
        currentPage: 1, // 当前页数
        totalPage: 0, // 总页数
        pageSize: 20, //
        pageSizes: [10, 20, 30, 50]
      },
      tableData: []
    }
  },
  created() {
    this.getTemplatelist()
  },
  methods: {
    // 编辑
    edit(id) {
      console.log("编辑功能", id)
      this.$router.push({
        name: "Home/Device/EditTem",
        query: {
          id
        }
      })
    },
    // 删除指定模板
    delTemplate(id) {
      this.$confirm(this.fun.getLanguageText(this.$store.state.language, '此操作将永久删除该参数配置模板, 是否继续?'), 
	  this.fun.getLanguageText(this.$store.state.language, '提示'), {
        confirmButtonText: this.fun.getLanguageText(this.$store.state.language, '确定'),
        cancelButtonText:this.fun.getLanguageText(this.$store.state.language, '取消') ,
        type: 'warning'
      }).then(async () => {
        let res = await this.$http.post("template/delete", {id})
        if (res.data.code !== 2000) {
          this.$message.error(this.fun.getLanguageText(this.$store.state.language, res.data.msg))
          return
        }
        this.$message({
          type: 'success',
          message: this.fun.getLanguageText(this.$store.state.language, '删除成功!')
        });
        await this.getTemplatelist()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.fun.getLanguageText(this.$store.state.language, '已取消删除!')
        });
      });
    },
    // 读取模板列表
    async getTemplatelist() {
      let res = await this.$http.post("template/list",
          {
            search: this.search,
            currentPage: this.pageData.currentPage,
            pageSize: this.pageData.pageSize
          })
      if (res.data.code !== 2000) return
      this.tableData = res.data.data.info
      this.pageData.total = Number(res.data.data.page.total)
      this.pageData.currentPage = res.data.data.page.currentPage
      this.pageData.totalPage = res.data.data.page.totalPage
      console.log(res.data)
    },
    // 改变页码
    currentChange(e) {
      this.pageData.currentPage = e
      this.getTemplatelist()
    },
    // 选择页码
    sizeChange(e) {
      this.pageData.pageSize = e
      this.getTemplatelist()
    },
  }
}
</script>

<style lang="less" scoped>
.footer {
  display: flex;
  padding: 0;
  margin-top: 14px;
  justify-content: flex-end;
}
</style>
